<template>
  <router-view/>
</template>


<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState([
        'headerHeight',
        'key'
      ])
    },
    created: function(){
      this.$store.dispatch('addKey');
    },
  }
</script>

<style lang="scss">
  @import '~normalize.css';

  html{
    height: 100%;
  }

  body{
    font-family: "Open Sans", Times, "Times New Roman", serif;
    color: #333;


    &.referencerequest{
      background-color: #eee;
    }
  }

  body::-webkit-scrollbar {
    width: 12px;
  }

  body::-webkit-scrollbar-thumb {
    background: #C1C1C1;
  }

  body::-webkit-scrollbar-track {
    background: #F1F1F1
  }
  body::-webkit-scrollbar-thumb:hover {
    background: #9F9F9F;
  }

  #app{
    height: 100%;
  }


</style>
