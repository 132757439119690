import { createStore } from 'vuex'

export default createStore({
  state() {
    return{
      mobileHeaderExpanded: true,
      headerHeight: null,
      key: null,
      locale: null,
      introPassed: false
    }
  },
  mutations: {
    setMobileHeaderExpanded(state, payload){
      this.state.mobileHeaderExpanded = payload;
    },
    setHeaderHeight(state, payload){
      this.state.headerHeight = payload;
    },
    setKey(state, payload){
      state.key = payload;
    },
    setLocale(state, payload){
      state.locale = payload;
    },
    setIntroPassed(state){
      state.introPassed = true;
    }
  },
  actions: {
    addKey( { commit } ){
      let currentUrl = window.location;
      let url = new URL(currentUrl);
      let key = url.searchParams.get("key");
      commit('setKey', key);
    }
  }
});
