import { createRouter, createWebHistory } from 'vue-router';
import VueBodyClass from 'vue-body-class'

const routes = [
  {
    path: '/recaptcha',
    name: 'Recaptcha',
    component: () => import('../views/Recaptcha.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue')
  },
  {
    path: '/',
    name: 'Form',
    component: () => import('../views/Form.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/NotFound.vue'),
    props: true
  },
  {
    path: '/intro',
    name: 'Intro',
    component: () => import('../views/IntroSinglePage.vue')
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.environment === 'development' ? '/' : '/'),
  routes
})

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });

export default router
