import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mitt from 'mitt';
import VueClickAway from "vue3-click-away";

let emmiter = mitt();
emmiter.$on = emmiter.on;
emmiter.$emit = emmiter.emit;

export const eventBus = emmiter;

createApp(App).use(VueClickAway).use(router).use(store).mount('#app');
